<template>
  <v-dialog v-bind="attrs" :fullscreen="$vuetify.display.mobile">
    <slot />
  </v-dialog>
</template>

<script setup>
const attrs = useAttrs();
</script>

<style lang="scss" scoped></style>